<template>
  <div class="tooltip-colors">
    <div class="tooltip-colors__item">
      <slot/>
    </div>
    <div class="tooltip-colors__hover">
      <div
        ref="refColors"
        class="tooltip-colors__colors"
      >
        <div
          v-for="(colorItem, index) in colorsList"
          :key="`colorItem-${ index }`"
        >
          <ColorItemForTooltip
            :color-item="colorItem"
          />
        </div>
      </div>
      <nuxt-link
        :to="productUrl"
        class="tooltip-colors__link"
      >
        Смотреть все
        <img
          src="~/assets/img/svg/common/arrow.svg"
          alt="развернуть"
        />
      </nuxt-link>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { ComputedRef } from 'vue';

import ColorItemForTooltip from "./ColorItemForTooltip.vue";

interface IColorItem {
  imageId: Number,
  value: String
}
interface IProps {
  colors: IColorItem[],
  productUrl: any
}

const props = withDefaults(defineProps<IProps>(),{
  colors: () => []
});

const colorsList: ComputedRef<IColorItem[]> = computed(() => {
  return [...(props.colors || [])].splice(0, 24)
});
</script>

<style lang="scss">
.tooltip-colors {
  position: relative;

  &:hover {
    .tooltip-colors__hover {
      display: block;
    }
  }
}
.tooltip-colors__hover {
  display: none;
  position: absolute;
  right: 0;
  bottom: calc(100% + 20px);
  z-index: 12;

  filter: drop-shadow(6px 4px 31px rgba(0, 0, 0, 0.05));
  background-color: white;
  border-radius: 10px 10px 0 10px;
  padding: 16px;
  box-sizing: border-box;

  width: 234px;

  &:after {
    content: "";
    position: absolute;
    top: 100%; right: 0;
    width: 14px; height: 14px;
    background-color: white;
    pointer-events: none;
    clip-path: polygon(100% 0, 0 0, 100% 100%);
    transform: translateY(-1px);
  }
  &:before {
    content: "";
    position: absolute;
    top: 100%; left: 0; right: 0;
    height: 20px;
  }
}
.tooltip-colors__colors {
  display: grid;
  gap: 8px;
  grid-template-columns: repeat(6, 1fr);

  .image-item {
    position: relative;
    overflow: hidden;
    border-radius: 7px;

    img {
      position: absolute;
      top: -2px; left: -2px;
      width: calc(100% + 4px);
      height: calc(100% + 4px);
      max-width: initial;
      max-height: initial;
    }
    &:after {
      content: "";
      float: left;
      padding-top: 100%;
    }
    &:before {
      content: "";
      position: absolute;
      top: 0; left: 0;
      width: 100%; height: 100%;
      //box-shadow: inset 0 0 0 1px #989898;
      border-radius: 7px;
      z-index: 2;
      pointer-events: none;
      border: 1px solid #989898;
    }
  }
  .tooltip__hover {
    z-index: 3;
  }
}
.tooltip-colors__link {
  display: flex;
  align-items: center;
  margin-top: 10px;
  height: 15px;

  font-weight: 500;
  font-size: 12px;
  line-height: 100%;
  color: #333333;
  opacity: 0.5;

  img {
    width: 10px;
    height: 10px;
    margin-left: 5px;
    object-fit: contain;
    transform: rotate(180deg);
  }
  &:hover {
    opacity: 1;
  }
}

@media (max-width: 1023px) {
  .tooltip-colors__hover {
    display: none!important;
  }
}
</style>
